import { appProvider } from './../../../app-providers'
import { when, computed, action, reaction, IReactionDisposer, observable, toJS } from 'mobx'
import { walletStore } from '@/stores/wallet-store'
import { asyncAction } from 'mobx-utils'
import { groupBy, isEmpty, map, every } from 'lodash'
import { FixedNumber } from '@ethersproject/bignumber'
import { web3 } from '@/helpers/bsc-helper'
import { MAX_ACCOUNT_PER_TRANS, Zero } from '@/constants'
import { SolidityMultiSendContract } from '@/blockchainHandlers/multisend-contract-solidity'
import { chunk } from 'lodash-es'
import { blockchainHandler } from '@/blockchainHandlers'
import { ITokenInfo } from '@/blockchainHandlers/multisend-contract-interface'
import { PublicKey } from '@solana/web3.js'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

export default class SenderViewModel {
  @observable currentStep = 1
  @observable bnbInfo: ITokenInfo = { decimals: '', name: '', symbol: '', balance: Zero }
  @observable codeMirrorText = ''
  @observable isUploadFile = false
  @observable walletEditor: any
  @observable errorList: any = {}
  @observable duplicateList: any = {}
  @observable walletEditorData: any = []
  @observable isEmptyError = false
  @observable transNumber = 0
  @observable totalAmount = Zero
  @observable selectedToken = ''
  @observable selectedTokenInfo: ITokenInfo = { decimals: '', name: '', symbol: '', balance: Zero }
  @observable isApproved = false
  @observable approveLoading = false
  @observable data = []
  @observable tokenErrorMessage = ''
  @observable isVIP = false
  @observable txFee = FixedNumber.from('0')
  @observable addressChecking = false
  @observable gasEstimate = FixedNumber.from('0')
  @observable openTransInfoDialog = false
  @observable transactionInfo = ''

  multiSendHandler?: any

  _disposers: IReactionDisposer[] = []

  constructor() {
    this.loadData()
    this._disposers = [
      reaction(
        () => walletStore.account,
        async () => {
          if (walletStore.connected) {
            await this.loadData()
          }
        }
      )
    ]
  }
  destroy() {
    this._disposers.forEach(d => d())
  }

  @asyncAction *loadData() {
    try {
      if (+walletStore.chainId === 101 || +walletStore.chainId === 102 || +walletStore.chainId === 103) {
        this.multiSendHandler = blockchainHandler.multiSendContractFactory({
          chainId: walletStore.chainId
        }) as any
        if (this.multiSendHandler) this.bnbInfo = yield this.multiSendHandler.getSOLInfo(walletStore.account)
        this.selectedToken = this.bnbInfo.name
        this.selectedTokenInfo = this.bnbInfo
        return
      }
      this.multiSendHandler = blockchainHandler.multiSendContractFactory({
        chainId: walletStore.chainId
      }) as SolidityMultiSendContract
      if (this.multiSendHandler) yield this.multiSendHandler.init()
      this.isVIP = yield this.multiSendHandler.isVIP(walletStore.account)
      this.txFee = yield this.multiSendHandler._txFee
      this.bnbInfo = yield this.multiSendHandler.getBnbInfo(walletStore.account)
      this.selectedToken = this.bnbInfo.name
      this.selectedTokenInfo = this.bnbInfo
    } catch (error) {
      console.log(error)
    }
  }

  @action.bound setCurrentStep(value: number) {
    this.currentStep = value
  }

  @asyncAction *checkTokenAddress(tokenAddress: string) {
    if (tokenAddress === this.bnbInfo?.symbol) {
      this.selectedTokenInfo = this.bnbInfo
      this.tokenErrorMessage = ''
      return
    }
    if (walletStore.chainType === 'sol') {
      this.selectedTokenInfo = yield this.multiSendHandler &&
        this.multiSendHandler.getTokenInfoByAddress(tokenAddress, walletStore.account)
      this.selectedToken = this.selectedTokenInfo?.name
      return
    } else {
      const isAddress = walletStore?.web3?.utils?.isAddress(tokenAddress)
      if (isAddress) {
        this.selectedTokenInfo = yield this.multiSendHandler &&
          this.multiSendHandler.getTokenInfoByAddress(tokenAddress, walletStore.account)

        this.selectedToken = this.selectedTokenInfo?.name
        this.tokenErrorMessage = ''
        return
      }
    }

    // this.tokenErrorMessage = 'Invalid token'
  }

  @action.bound changeState(state: any) {
    if (state.selectedToken) this.selectedToken = state.selectedToken
    if (state.walletEditorData) this.walletEditorData = state.walletEditorData
    if (state.transNumber) this.transNumber = state.transNumber
    if (state.totalAmount) this.totalAmount = state.totalAmount
  }

  @action changeUploadFileState(isUploadFile: boolean) {
    this.isUploadFile = isUploadFile
  }

  @action changeCodeMirrorText(text: string) {
    this.codeMirrorText = text
  }

  @action setWalletEditor(editor: any) {
    this.walletEditor = editor
  }

  @action toggleTransInfoDialog() {
    this.openTransInfoDialog = !this.openTransInfoDialog
  }

  @action showTransInfoDialog(data: any) {
    this.openTransInfoDialog = true
    this.transactionInfo = this.convertArrayToString(data)
  }

  @action validatePrepareStep() {
    // this.tokenErrorMessage = isEmpty(this.selectedTokenInfo.symbol) && !this.isUploadFile ? 'Invalid token' : ''
    const editorData: any = []
    const errorList: any = {}
    const duplicateList: any = {}
    let i = 0
    let line = 1
    //handle invalid address and wrong amount
    this.walletEditor.eachLine(f => {
      this.walletEditor.removeLineClass(line - 1, 'wrap', 'line-error')
      if (isEmpty(f.text)) {
        line++
        return
      }
      let errorMsg = ''
      let arr = f.text.trim().split(',')
      arr = arr.map(item => item.trim())
      if (walletStore.chainType === 'sol') {
        let isAddress = false
        try {
          const publicKey = new PublicKey(arr[0]).toBytes()
          if (PublicKey.isOnCurve(publicKey)) isAddress = true
        } catch (error) {
          console.log(error)
        }
        if (arr.length < 2 || !new RegExp(/^\d+(\.\d*)?$/).test(arr[1])) {
          errorMsg = web3.utils.isAddress(arr[0])
            ? 'wrong amount'
            : `${arr[0]} is a invalid wallet address and wrong amount`
        } else if (!isAddress) errorMsg = `${arr[0]} is a invalid wallet address`
      } else {
        if (arr.length < 2 || !new RegExp(/^\d+(\.\d*)?$/).test(arr[1])) {
          errorMsg = web3.utils.isAddress(arr[0])
            ? 'wrong amount'
            : `${arr[0]} is a invalid wallet address and wrong amount`
        } else if (!web3.utils.isAddress(arr[0])) errorMsg = `${arr[0]} is a invalid wallet address`
      }
      if (!isEmpty(errorMsg)) {
        errorList[line] = errorMsg
        editorData[i] = { text: f.text, isInvalidParam: true }
      } else {
        editorData[i] = {
          text: f.text,
          isInvalidParam: false,
          address: arr[0],
          amount: FixedNumber.from(arr[1]),
          line: line
        }
      }
      i++
      line++
    })
    const validData = editorData.filter(item => !item.isInvalidParam)
    const groupByAddress = Object.values(groupBy(validData, 'address'))
    groupByAddress.map((group: any) => {
      if (group.length > 1) {
        group.map((item, index) => {
          if (index > 0) duplicateList[item.line] = `duplicate address ${item.address}`
        })
      }
    })
    Object.keys(errorList).map(key => {
      this.walletEditor.addLineClass(parseInt(key) - 1, 'wrap', 'line-error')
    })
    Object.keys(duplicateList).map(key => {
      this.walletEditor.addLineClass(parseInt(key) - 1, 'wrap', 'line-error')
    })
    this.errorList = errorList
    this.duplicateList = duplicateList
    this.walletEditorData = editorData
  }

  @action prepareNextAction() {
    try {
      this.validatePrepareStep()
      if (this.errorCount === 0 && this.duplicateCount === 0 && !isEmpty(this.selectedTokenInfo.symbol)) {
        if (this.walletEditorData.length === 0) {
          this.isEmptyError = true
          return false
        } else {
          const transNumber =
            walletStore.chainType === 'sol'
              ? Math.ceil(parseFloat(`${this.walletEditorData.length}`) / 20)
              : Math.ceil(parseFloat(`${this.walletEditorData.length}`) / MAX_ACCOUNT_PER_TRANS)
          const totalAmount = this.walletEditorData.reduce((a, b) => {
            if (a.isZero()) return b.amount
            else return a.addUnsafe(b.amount)
          }, Zero)
          const codeMirrorText = this.walletEditor.getValue()
          this.changeState({
            transNumber,
            totalAmount,
            codeMirrorText,
            walletEditorData: this.walletEditorData,
            multiSendHandler: this.multiSendHandler
          })
          return true
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  resetSeletedTokenInfo() {
    this.selectedTokenInfo = { decimals: '', name: '', symbol: '', balance: Zero }
  }

  async handleEstimateGas() {
    const arr = chunk(this.walletEditorData, MAX_ACCOUNT_PER_TRANS)
    try {
      const promises: any = []
      arr.map(async item => {
        const addresses = map(item, 'address')
        const values = map(item, 'amount')
        promises.push(this.multiSendHandler?.estimateGasBulkSend(walletStore.account, addresses, values, this.bnbInfo))
      })
      const estimates = await Promise.all(promises)
      const totalEstimates = estimates.reduce((a: FixedNumber, b) => {
        return a.addUnsafe(b as FixedNumber)
      }, Zero)
      this.gasEstimate = totalEstimates
      return totalEstimates
    } catch (e) {
      console.error(e)
    }
  }

  async deleteLines() {
    this.validatePrepareStep()
    const finalData = this.walletEditorData.filter(item => !item.isInvalidParam)
    const convertedString = this.convertArrayToString(finalData)
    this.walletEditor.setValue(convertedString)
    this.validatePrepareStep()
  }

  convertArrayToString(arr) {
    let s = ''
    arr.map((item, index) => {
      const breakLine = arr.length - 1 === index ? '' : '\n'
      if (item.isInvalidParam) s = s + `${item.text}${breakLine}`
      else s = s + `${item.address},${item.amount}${breakLine}`
    })
    return s
  }

  @action handleCombine() {
    this.validatePrepareStep()
    const validData = this.walletEditorData.filter(item => !item.isInvalidParam)
    const group = groupBy(validData, 'address')
    const tmp = {}
    Object.keys(group).map(key => {
      if (group[key].length > 1) {
        tmp[key] = group[key].reduce((a, b) => {
          if (a.isZero()) return b.amount
          else return a.addUnsafe(b.amount)
        }, Zero)
      }
    })
    const duplicateAddressArr = Object.keys(tmp)
    const filterCombinedData = this.walletEditorData.filter((item, index) => {
      if (item.isInvalidParam || !duplicateAddressArr.includes(item.address)) return true
      const firstIndex = this.walletEditorData.findIndex(f => f.address === item.address)
      if (firstIndex === index) return true
    })
    const finalData = filterCombinedData.map(item => {
      if (duplicateAddressArr.includes(item.address) && !item.isInvalidParam) item.amount = tmp[item.address]
      return item
    })
    const convertedString = this.convertArrayToString(finalData)
    this.walletEditor.setValue(convertedString)
    this.validatePrepareStep()
  }

  @computed get errorCount() {
    return Object.keys(this.errorList).length
  }
  @computed get duplicateCount() {
    return Object.keys(this.duplicateList).length
  }

  @asyncAction *approve() {
    try {
      this.approveLoading = true
      const confirmed = yield this.multiSendHandler?.approveContract(walletStore.account, this.selectedTokenInfo)
      if (confirmed)
        this.isApproved = yield this.multiSendHandler?.approvedContract(walletStore.account, this.selectedTokenInfo)
    } catch (error) {
      console.log(error)
    } finally {
      this.approveLoading = false
      // yield this.handleEstimateGas()
    }
  }

  @asyncAction *createSolanaAssociation() {
    try {
      this.approveLoading = true
      const infoChunks = chunk(this.walletEditorData, 10)
      for (let i = 0; i < infoChunks.length; i++) {
        const addresses = map(infoChunks[i], 'address')
        yield this.multiSendHandler?.createAssociatedTokenAccountInstruction(
          walletStore.account,
          addresses,
          this.selectedTokenInfo
        )
      }
      this.isApproved = true
    } catch (e) {
      snackController.error(e.message || e.msg)
    } finally {
      this.approveLoading = false
    }
  }

  // eslint-disable-next-line require-yield
  @asyncAction *handleSending() {
    this.data = []
    const arr = chunk(this.walletEditorData, MAX_ACCOUNT_PER_TRANS)
    try {
      arr.map(async (item, index) => {
        // const transactionInfo = this.convertArrayToString(item)
        const transactionInfo = item
        const callback = ({ completed }) => {
          const trans: any = this.data[index]
          if (trans?.transactionInfo) {
            trans.loading = false
            trans.state = completed
            trans.displayCopyIcon = true
          } else {
            const data = [...this.data, { loading: false, state: false, transactionInfo, displayCopyIcon: true }]
            this.changeData(data)
          }
        }
        const addresses = map(item, 'address')
        const values = map(item, 'amount') as FixedNumber[]
        const fee: FixedNumber = this.isVIP ? FixedNumber.from('0') : this.txFee
        const res = await this.multiSendHandler?.bulkSend(
          addresses,
          values,
          fee,
          walletStore.account,
          callback,
          this.selectedTokenInfo
        )
        const data = [...this.data, { hash: res, loading: true, state: false, transactionInfo, displayCopyIcon: false }]
        this.changeData(data)
      })
    } catch (e) {
      snackController.error(e.message)
    }
  }

  // eslint-disable-next-line require-yield
  @asyncAction *handleSolanaSend() {
    const infoChunks = chunk(this.walletEditorData, 20)

    const intData = new Array(infoChunks.length).fill({
      loading: true,
      state: false,
      displayCopyIcon: false,
      waiting: true
    })
    this.changeData(intData)

    for (let i = 0; i < infoChunks.length; i++) {
      const transactionInfo = infoChunks[i]
      const callback = ({ completed }) => {
        const trans: any = this.data[i]
        if (trans.hash) {
          trans.loading = false
          trans.displayCopyIcon = true
          trans.state = completed
        } else {
          const data = [...this.data]
          data[i] = {
            loading: false,
            state: false,
            transactionInfo,
            displayCopyIcon: true,
            waiting: false,
            index: i
          } as never
          this.changeData(data)
        }
      }
      const addresses = map(infoChunks[i], 'address')
      const values = map(infoChunks[i], 'amount')
      if (this.selectedTokenInfo.tokenAddress) {
        const res = yield this.multiSendHandler.bulkSend(
          addresses,
          values,
          walletStore.account,
          this.selectedTokenInfo,
          callback
        )
        const data = [...this.data]
        data[i] = {
          hash: res,
          loading: false,
          state: false,
          transactionInfo,
          displayCopyIcon: false,
          waiting: false,
          index: i
        } as never
        this.changeData(data)
      } else {
        const res = yield this.multiSendHandler.bulkSendSol(addresses, values, walletStore.account, this.selectedTokenInfo, callback)
        const data = [...this.data]
        data[i] = {
          hash: res,
          loading: false,
          state: false,
          transactionInfo,
          displayCopyIcon: false,
          waiting: false
        } as never
        this.changeData(data)
      }
    }
  }

  @asyncAction *retrySolanaSend(infoChunk: any) {
    const transactionInfo = infoChunk.transactionInfo
    const index = infoChunk.index
    const callback = ({ completed }) => {
      const trans: any = this.data[index]
      if (trans.hash) {
        trans.loading = false
        trans.state = completed
      } else {
        const data = [...this.data]
        data[index] = {
          loading: false,
          state: false,
          transactionInfo,
          displayCopyIcon: true,
          waiting: false,
          index
        } as never
        this.changeData(data)
      }
    }
    const addresses = map(transactionInfo, 'address')
    const values = map(transactionInfo, 'amount')
    if (this.selectedTokenInfo.tokenAddress) {
      const res = yield this.multiSendHandler.bulkSend(
        addresses,
        values,
        walletStore.account,
        this.selectedTokenInfo,
        callback
      )
      const data = [...this.data]
      data[index] = {
        hash: res,
        loading: false,
        state: false,
        transactionInfo,
        displayCopyIcon: false,
        waiting: false,
        index
      } as never
      this.changeData(data)
    } else {
      const res = yield this.multiSendHandler.bulkSendSol(addresses, values, walletStore.account, callback)
      const data = [...this.data]
      data[index] = {
        hash: res,
        loading: false,
        state: false,
        transactionInfo,
        displayCopyIcon: false,
        waiting: false
      } as never
      this.changeData(data)
    }
  }

  @action changeData(data: any) {
    this.data = [...data] as any
  }

  @computed get selectedTokenName() {
    return `${this.selectedTokenInfo.symbol}-${this.selectedTokenInfo?.tokenAddress}`
  }

  @computed get isCompletedSending() {
    return this.data.length === this.transNumber && every(this.data, ['loading', false])
  }
  @computed get isErrorSending() {
    return this.isCompletedSending && every(this.data, ['state', false])
  }
}
