














import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import SenderViewModel from '../viewmodels/sender'

@Observer
@Component({
  components: {
    Stepper: () => import('../components/stepper.vue')
  }
})
export default class Sender extends Vue {
  @Provide() vm = new SenderViewModel()

  walletStore = walletStore

  destroyed() {
    this.vm.destroy()
  }
}
